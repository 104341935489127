import styled from 'styled-components';
import { BRAND, GREYS, FONT, FUNCTIONAL } from 'UI/globals/colours';
import { getModalWidth } from '../../../components/LoginApp/core__login-store';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';
import ModalButton from 'UI/buttons/ModalButton';

export const PC = styled.div`
  width: 100%;
  text-align: center;
  height: 24px;
  color: ${GREYS.white};
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
`;
export const MStyles = {
  modal: {
    padding: '24px',
    width: getModalWidth(BREAKPOINT_M),
    borderRadius: '10px',
    background: BRAND.secondaryDark,
    color: GREYS.white,
    Zindex: 10,
    border: '1px solid #2c2c32',
    boxShadow: '0 16px 48px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
};
export const BC = styled.div`
  color: ${GREYS.white}
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: left;
`;
export const CV = styled.div`
  inline-size: 90%;
  overflow: hidden;
  color: ${GREYS.white};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 20px;
`;

export const BT = styled(ModalButton)`
  background-color: ${({ theme }) =>
    theme.dark ? FONT.darkMute : BRAND.secondary};
  border-radius: 24px;
  text-align: center;
  margin: 10px 0px 4px 0px;
  text-transform: uppercase;
`;
export const P = styled.div`
  font-size: 12px;
  font-weight: 600;
`;
export const TM = styled.div`
  display: flex;
  padding-top: 15px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  text-align: justfy;
  border-style: solid;
  border: 1px solid ${FUNCTIONAL.timerBB};
  background-color: rgba(76, 196, 255, 0.1);
`;
export const BR = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: left;
`;
export const BF = styled.div`
  margin-top: 30px;
  inline-size: 70%;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: left;
  color: ${GREYS.white};
  margin-bottom: 10px;
`;
export const IN = styled.div`
  margin: 15px 0px 15px 0px;
  padding: 20px 0px 10px 0px;
  width: 100%;
  font-size: 13px;
  color: ${GREYS.white};
  margin-bottom: 20px;
  font-weight: 500;
  border-top: 1px solid ${FUNCTIONAL.timerB};
  border-bottom: 1px solid ${FUNCTIONAL.timerB};
`;
export const CA = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto auto;
`;
export const CB = styled.div`
  font-size: 18px;
  text-align: left;
  width: 100%;
`;

export const CC = styled.div`
  display: flex;
  color: ${GREYS.white};
  font-family: 'Roboto - Bold';
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin: 12px 0px 8px 0px;
`;

export const CE = styled.div`
  font-size: 12px;
  padding: 0px;
  margin: 4px 0px 4px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  color: ${FUNCTIONAL.modalFont};
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
`;

export const SC = styled.div`
position: fixed;
opacity: 0;
transition: 200ms ease-in-out;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: ${GREYS.white}
pointer-events: none;
`;

export const SM = styled.div`
  opacity: 1;
  pointer-events: all;
`;

export const EUR = styled.div`
  height: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  text-align: justfy;
  color: ${GREYS.white};
`;

export const FX = styled.div`
  display: flex;
  flex-direction: row;
`;

export const C = styled.div``;
export const ICON = styled.div`
  width: 24px;
  height: 24px;
  color: black;
  margin-left: 15px;
`;
export const TIM = styled.div`
width: 230px;
height: 20px;
color: ${GREYS.white}
font-size: 14px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 20px;
text-align: justfy;
`;

export const B = styled.div`
  display: flex;
`;
