import React from 'react';
import { useState, useEffect } from 'react';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; // Do not use local import
import {
  BC,
  PC,
  C,
  BT,
  TM,
  BR,
  IN,
  CA,
  CB,
  CC,
  CE,
  SC,
  SM,
  EUR,
  FX,
  CV,
  BF,
  ICON,
  TIM,
  B,
  MStyles,
} from 'UI/apps/SkResponsibleGamblePopupApp/SkResponsibleGamblePopupApp-React';
import { getRequest } from 'Services/http/core__axios';
import { Translations } from '../core__sk-responsible-gambling-popup-app';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import {
  getCookie,
  createCookie,
  removeCookie,
} from 'Services/cookie/core__cookies';
import PropTypes from 'prop-types';

const PopUp = () => {
  const [handleModal, setHandleModal] = useState(false);
  const [showElement, setShowElement] = useState(true);
  const [countDown, setCountDown] = useState();
  const [dates, setDate] = useState([]);
  const [betSummaryFromActivation, setbetSummaryFromActivation] = useState([]);
  const [betSummaryFromCurrentMonth, setbetSummaryFromCurrentMonth] = useState(
    []
  );
  const [triger1, setTriger1] = useState(false);
  const Timer = () => {
    setShowElement(false);
    var counter = 5;
    setCountDown(counter);
    var time = setInterval(() => {
      setCountDown(counter--);
      if (counter < 0) {
        setShowElement(true);
        clearInterval(time);
        setHandleModal(false);
      }
    }, 1000);
  };
  setTimeout(() => {
    removeCookie('tAppSession');
    if (typeof window !== 'undefined') {
      window.sessionStorage.removeItem('RegCompleted');
    }
  }, 5000);

  const SessionFetchData = () => {
    const inter = setInterval(() => {
      if (getCookie('AppSession')) {
        clearInterval(inter);
        Timer();
        FetchData();
        setHandleModal(true);
      }
    }, 200);
  };

  const FetchData = () => {
    if (!getCookie('AppSession')) return;

    const url = `/fsb-api-rest/customer/summary.fsb?access_token=${getCookie(
      'AppSession'
    )}&game=true&summary=true`;
    const requester = getRequest;
    return requester(url).then(res => {
      setDate(res?.customer);
      setbetSummaryFromActivation(res.customer?.casinoFromActivation);
      setbetSummaryFromCurrentMonth(res.customer?.casinoFromCurrentMonth);
    });
  };
  useEffect(() => {
    if (window && window.sessionStorage.getItem('RegCompleted') === 'true') {
      createCookie('tAppSession', getCookie('AppSession'));
      SessionFetchData();
    }

    // Block of code that trigers on Responsible Gamble button
    const openModalListener = PubSub.listen(PubsubEvents.openModals, () => {
      setHandleModal(true);
      FetchData();
    });
    const sessionCreatedListener = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      wsData => {
        if (
          window &&
          window.sessionStorage.getItem('RegCompleted') !== 'true'
        ) {
          const registerStatus = wsData?.raw?.customerMetadata?.find(
            metaInfo => metaInfo?.type === 'fully_registered'
          );
          if (
            registerStatus?.value === 'true' ||
            wsData?.raw?.accountStatus === 'ACTIVE'
          ) {
            createCookie('tAppSession', getCookie('AppSession'));
            SessionFetchData();
          }
        }
      }
    );

    window.onload = function () {
      var testcookie = getCookie('tAppSession');
      if (testcookie) {
        Timer();
        setHandleModal(true);
        FetchData();
      }
    };

    return () => {
      openModalListener?.unsubscribe();
      sessionCreatedListener?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //  This event is set in the  of LoginApp so when Login App
  //  user clicks login and when functions trigers in LoginStore after, auth is checked
  // it will trigger this block of code underneath, wich has double check.
  useEffect(() => {
    const openModalListener = PubSub.listen(
      PubsubEvents.OpenResponsibleModalPopUpLogin,
      app => {
        setTriger1(app);
        triger1 && createCookie('tAppSession', getCookie('AppSession'));
        SessionFetchData();
      }
    );

    return () => {
      openModalListener?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const convert = new Date(dates?.firstActivated).toLocaleDateString('sk');
  const date = convert.replace(/\//g, '.');

  return (
    <React.Fragment>
      <Modal
        data-test="close-modal"
        open={handleModal}
        center
        styles={MStyles}
        onClose={() => setHandleModal(false)}
      >
        <PC>{Translations.get('text.responsible.gambling')}</PC>
        <CV>{Translations.get('text.header.prohibition.of.gambling')}</CV>

        <IN>
          <BC>{Translations.get('text.information.total.bets')}</BC>
          <CA>
            <CB>
              <CC>{Translations.get('text.calendar.month')}</CC>

              <CE>
                <C>{Translations.get('text.total.bets')}</C>
                <B>
                  <EUR>{betSummaryFromCurrentMonth?.totalStake} </EUR>
                  <EUR> {Translations.get('text.eur')}</EUR>
                </B>
              </CE>
              <CE>
                <C>{Translations.get('text.total.winings')}</C>
                <B>
                  <EUR>{betSummaryFromCurrentMonth?.totalWinning} </EUR>
                  <EUR> {Translations.get('text.eur')}</EUR>
                </B>
              </CE>
            </CB>
            <CB>
              <CC>
                {Translations.get('from.activation.date')} {date}
              </CC>
              <CE>
                <C>{Translations.get('text.total.bets')}</C>
                <B>
                  <EUR>{betSummaryFromActivation?.totalStake} </EUR>
                  <EUR> {Translations.get('text.eur')}</EUR>
                </B>
              </CE>
              <CE>
                <C>{Translations.get('text.total.winings')}</C>
                <B>
                  <EUR>{betSummaryFromActivation?.totalWinning} </EUR>{' '}
                  <EUR>{Translations.get('text.eur')}</EUR>
                </B>
              </CE>
            </CB>
          </CA>
        </IN>

        <BR>
          <FX>
            <C>{Translations.get('text.gaming.highline')}&nbsp;</C>
            <C>
              <a
                style={{ textDecorationLine: 'underline' }}
                href="https://www.urhh.sk/web/guest/linka-pomoci-pre-problemy-s-hranim/"
              >
                {Translations.get('text.information')}
              </a>
            </C>
          </FX>
          <FX>
            <C>
              <a
                style={{ textDecorationLine: 'underline' }}
                href="https://www.urhh.sk/web/guest/ziadost_spristupnenie_udajov"
              >
                {Translations.get('text.application.from.exclusion')}
                &nbsp;
              </a>
            </C>
            <C>{Translations.get('text.fromgaming')}</C>
          </FX>
        </BR>
        <BF>{Translations.get('text.measures')}</BF>
        {showElement ? (
          <BT
            data-test="close-success-modal"
            onClick={() => {
              setHandleModal(false);
            }}
            label={Translations.get('text.close')}
          ></BT>
        ) : (
          <TM>
            <ICON>&#128337; </ICON>{' '}
            <TIM>{`${Translations.get(
              'text.this.window.will.close'
            )} 00:0${countDown}`}</TIM>
          </TM>
        )}
        {!showElement ? <SC></SC> : <SM></SM>}
        {/* Line 209 uses pointer events that disables a screen when it's needed */}
      </Modal>
    </React.Fragment>
  );
};
export default PopUp;

PopUp.propTypes = {
  responsibleGamingData: PropTypes.array,
  handleModal: PropTypes.func,
  data: PropTypes.array,
  countDown: PropTypes.number,
  showElement: PropTypes.func,
  dates: PropTypes.number,
  betSummaryFromCurrentMonth: PropTypes.array,
  betSummaryFromActivation: PropTypes.array,
  FetchData: PropTypes.func,
};
